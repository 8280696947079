





















































nav {
  margin-top: 1.45rem;

  @media screen and (min-width: 1920px) {
    margin-top: 1rem;

    li:not(:last-child) {
      padding-top: 0.15rem;
    }
  }
}

.parents {
  display: grid;
  grid-template-columns: repeat(4, 1fr) 9rem; // 6rem, if search is on
  grid-template-rows: auto;

  .mainKnot {
    margin-bottom: 1rem;

    @media screen and (min-width: 1920px) {
      margin-bottom: 0.8rem;
    }
  }
}

.children {
  h3 {
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.4;
    text-transform: none;
    margin-bottom: 0;
  }
}

.ctaCont {
  width: calc(100% - 9rem);

  .cta {
    margin-left: 75%;
    position: relative;
    bottom: 4rem;
  }
}

.subnav {
  div {
    display: inline-block;
    margin-top: -0.2rem;

    @media screen and (min-width: 1350px) {
      margin-top: -0.1rem;
    }

    @media screen and (min-width: 1920px) {
      margin-top: 0;
    }

    &.search {
      margin-left: 1rem;
    }
  }
}
