




























button.ctaButton.gerunded {
  border-radius: 3rem;
}
#medicosearchWidget {
    height 70vh
    overflow scroll
    @media screen and (min-width: 768px){
        height 50vh
    }
    }
