// --------------------------------------
// PAGE ANIMATION FADE
// -------------------------------------
body {
    opacity: 1;
}

// --------------------------------------
// MAIN STUFF
// -------------------------------------
#app {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;

    @media screen and (min-width: 768px) {
        margin-top: 4rem;
    }

    @media screen and (min-width: 1920px) {
        margin-top: 5rem;
    }

    margin-bottom: 2rem;
}

#mainBody {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    margin-top: 3rem;

    @media screen and (min-width: 768px) {
        grid-template-columns: 2fr 1fr;
        grid-template-rows: auto;
        grid-gap: 1rem;

        .maintitle {
            grid-row: 1;
            grid-column: 1 / 2;
            margin-top: -0.35rem;

            @media screen and (min-width: 1400px) {
                margin-top: -0.5rem;
            }
        }

        .hero {
            grid-column: 2 / 3;
            grid-row: 2;
        }

        #stage {
            grid-row: 2;
            margin-top: -0.3rem;
        }

        &.teammitglied {
            #stage {
                grid-row: 1;
            }
        }

        .boxes {
            grid-column: 1 / 3;
        }

        aside {
            grid-column: 2 / 3;
            grid-row: 1;
        }

        &.nohero {
            margin-top: 2rem;
        }
    }

    @media screen and (min-width: 1920px) {
        &.nohero {
            margin-top: 4rem;
        }
    }
}

.kontakt {
    #mainBody {
        #stage {
            grid-row: 1;
        }
    }
}

.kurztext {
    a {
        text-decoration: underline;
        color: medinBlack;

        &:hover {
            color: medinBlack;
        }
    }
}

figure {
    margin-bottom: 2rem;
}

// --------------------------------------
// landingpage
// -------------------------------------
.landingPage {
    ul.switcheridooo {
        margin-left: 1rem;
        margin-top: 2rem;
        position: relative;
        list-style: none;
        padding-left: 2rem;
        display: flex;
        flex-direction: column;
        height: 40vh;
        justify-content: center;

        li {
            font-size: 1.25rem;
            line-height: 1.4;
            margin-bottom: 2rem;

            &::before {
                content: '→';
                color: medinBlue;
                position: absolute;
                left: 0;
                transition: left 0.3s ease-in-out;
            }

            &:hover {
                color: medinBlue;
            }

            &::before {
                left: 0.25rem;
            }

            a {
                text-decoration: none;
                color: medinBlue;
            }
        }
    }

    .switcheridooo {
        opacity: 0;
    }

    main {
        margin-top: 0;
    }

    .videoDone {
        overflow: hidden;
        position: relative;

        .outer {
            position: absolute;
            top: 0;
            width: 100%;
        }

        #myvid {
            transition: transform 0.3s ease;
            transform: translateY(-100%);
        }

        .switcheridooo {
            transition: opacity 0.3s ease;
            transition-delay: 0.3s;
            opacity: 1;
        }
    }
}

// --------------------------------------
// NAV
// -------------------------------------
nav {
    ul.children {
        li.active a {
            text-decoration: underline;
        }
    }
}

// --------------------------------------
// HERO
// -------------------------------------
.hero {
    position: relative;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
        margin-bottom: 2rem;
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 4rem;
    }

    .bild {
        width: 100%;
    }

    @media screen and (min-width: 786px) and (max-width: 1920px) {
        &.heroleft {
            img {
                height: 70vh;
                width: auto;
            }
        }
    }

    .lead {
        position: absolute;
        left: 0;
        top: 1rem;
        width: 100%;

        h2 {
            color: white;
        }
    }

    .ctas {
        display: none;

        @media screen and (min-width: 1050px) {
            display: block;
            position: absolute;
            top: 30%;
            width: 100%;
            text-align: right;

            &.nohero {
                display: none;
            }

            li {
                position: absolute;
                right: 0;

                &.bigredbutton {
                    right: 6.5rem;
                }

                &.blocklink {
                    top: 17rem;
                }
            }
        }

        @media screen and (min-width: 1400px) {
            top: 40%;
        }
    }
}

// --------------------------------------
// Main Content
// -------------------------------------
.maincontent {
    width: 100%;
}

// --------------------------------------
// TEST IN MAIN CONTENT
// -------------------------------------
.text {
    padding-left: 0;
    margin-bottom: 2rem;

    @media screen and (min-width: 768px) {
        margin-bottom: 75px;
        margin-bottom: 1.8rem;
    }

    a {
        color: medinBlack;
        text-decoration: underline;
    }

    p {
        margin-bottom: 2rem;
    }

    h3 {
        margin-bottom: 0.8rem;
    }

    ul {
        list-style-type: disc;
        list-style-position: outside;
        padding-left: 1.1rem;
        margin-bottom: 2.6rem;

        @media screen and (min-width: 768px) {
            width: 75%;
        }

        @media screen and (min-width: 1920px) {
            margin-bottom: 1.6rem;
            width: 50%;
        }

        li {
            line-height: 1.4;
        }
    }

    @media screen and (min-width: 768px) {
        h3 {
        }
    }

    @media screen and (min-width: 1080px) {
        width: 85%;
    }
}

// --------------------------------------
// Aside
// -------------------------------------

// --------------------------------------
// Inner Helper
.inner {
    // -------------------------------------
    max-width: 1640px;
    margin: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

// --------------------------------------
// Hyphens Helper
.hyphens {
    // -------------------------------------
    hyphens: auto;
}

// --------------------------------------
// Big Red Button
.bigredbutton {
    // -------------------------------------
    background-color: medinRed;
    width: 13rem;
    height: 13rem;
    border-radius: 6.5rem;
    text-align: center;

    a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h2 {
        margin-bottom: 0;
        padding-top: 0.4rem;
    }
}

// --------------------------------------
// Blocklink
.blocklink {
    // -------------------------------------
    width: 245px;
    background-color: medinBlue;
    color: white;
    text-align: right;
    height: 4rem;
    width: 13rem;

    a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    h2 {
        margin-bottom: 0;
        padding-left: 1rem;
        padding-top: 0.4rem;
    }
}

// --------------------------------------
// Self hosted Videos
video.selfhostedVideo {
    // -------------------------------------
    width: 100% !important;
    height: auto !important;
}

// --------------------------------------
// carousel
// -------------------------------------
.VueCarousel {
    .VueCarousel-pagination {
        text-align: left;
        margin: 0 0 1rem 1rem;
    }

    button.VueCarousel-dot {
        margin: 0 0.5rem 0 0;
        background: white;
        border: white solid 2px;
        opacity: 1;

        &.VueCarousel-dot--active {
            background: transparent;
        }
    }

    .VueCarousel-slide-adjustableHeight {
        display: block !important;
    }

    .VueCarousel-inner {
        align-items: flex-start !important;
    }
}

// --------------------------------------
// FOOTER
// -------------------------------------
footer {
    background-color: medinBlue;
    color: white;
    padding-top: 1rem;

    a, li, p {
    }

    .footerGrid {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        margin-top: 2rem;
        margin-bottom: 3rem;

        .footerlogo {
            width: 80%;
        }

        li {
            margin-bottom: 1rem;
        }

        @media screen and (min-width: 768px) {
            grid-template-columns: 4fr 4fr 2fr 1fr 1fr;
            grid-template-rows: auto;

            li {
                align-self: end;
            }

            .oeffnungszeiten, .links {
                padding-left: 1rem;
            }
        }
    }

    .socialIcons {
        li {
            display: inline-block;

            &:not(:last-child) {
                margin-right: 1rem;
            }

            margin-left: 0;
            width: 50px;
            width: 3rem;

            @media screen and (min-width: 768px) and (max-width: 900px) {
                width: 30px;
                width: 2rem;
            }
        }

        @media screen and (min-width: 768px) {
            text-align: right;
            margin-left: 1rem;
            margin-right: 0;
        }
    }
}

// --------------------------------------
// NOTFALL
// -------------------------------------
.red {
    header#mainHead {
        background-color: medinRed;
        transition: background-color 0.3s ease-in-out;

        &.active, &.is-open {
            background-color: dampedRed;
        }
    }

    .drawer {
        .red {
            display: none;
        }

        a.cta.white {
            background-color: medinWhite;
            color: medinRed;
        }
    }

    .hero {
        display: none;
    }

    .maincontent, #app, footer {
        background-color: medinRed;
        color: medinWhite;
    }

    .maincontent {
        @media screen and (min-width: 768px) {
            margin-top: 2rem;
        }

        @media screen and (min-width: 1920px) {
            margin-top: 8.43rem;
        }
    }
}

// --------------------------------------
// IMPRESSUM
// -------------------------------------
.blue {
    .hero {
        display: none;
    }

    .maincontent, #app, footer {
        background-color: medinBlue;
        color: medinWhite;
    }

    header#mainHead {
        transition: background-color 0.3s ease-in-out;

        &.active, &.is-open {
            background-color: dampedBlue;
        }
    }

    a {
        color: medinWhite;
    }
}

// --------------------------------------
// KONTAKT FORM
// -------------------------------------
#contactform {
    input, textarea {
        background-color: medinGrey;
        border: none;
        border-radius: 0;
        margin-top: 0.1rem;
        margin-bottom: 1rem;
        padding-left: 0.5rem;
        color: black;
        opacity: 1;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        width: calc(100% - 0.5rem);
        font-size: 18px;
        font-size: 1rem;

        @media screen and (min-width: 1920px) {
            height: 40px;
            height: 2.5rem;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
        }

        &::placeholder {
            color: black;
            font-size: 18px;
            font-size: 1rem;
        }

        &[type='submit'] {
            width: 50%;

            @media screen and (min-width: 1920px) {
                max-width: 121px;
                max-width: 7.56rem;
            }

            background-color: medinBlue;
            color: white;
            float: right;
        }
    }

    textarea {
        border: none;

        @media screen and (min-width: 1920px) {
            height: 4.8125rem;
            padding-top: 0.75rem;
        }
    }
}

#kontaktinfos {
    margin-bottom: 3rem;

    @media screen and (min-width: 1920px) {
        margin-bottom: 8.125rem;
    }

    a {
        color: medinBlack;
        text-decoration: underline;

        &:hover {
            color: medinBlack;
        }
    }
}

// --------------------------------------
// Google Map
// -------------------------------------
.Gmap {
    width: 100%;
    position: relative;

    a {
        display: block;
    }

    .btt {
        position: absolute;
        width: 100%;
        height: 2rem;
        bottom: 2rem;

        .inner, a, .longarrow {
            height: 100%;
        }

        a {
            text-align: right;
        }
    }
}

.kontakt main {
    margin-bottom: 0;
}

// --------------------------------------
// CTA BUTTON HELPER
// -------------------------------------
a.ctaButton, button.ctaButton {
    color: white;
    font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    display: inline-block;
    width: fit-content;
    margin-bottom: 2rem;

    &.blau {
        background-color: medinBlue;
    }

    &.rot {
        background-color: medinRed;
    }

    &.standard {
        line-height: 1.4;
        padding: 1rem;
    }

    &.big {
        line-height: 1.4;
        padding: 1rem 2rem;
    }

    &.gerunded {
        border-radius: 3rem;
    }
}

.red {
    a.ctaButton {
        color: medinRed;
        font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
        display: inline-block;
        margin-bottom: 4rem;

        &.blau {
            background-color: medinWhite;
        }

        &.rot {
            background-color: medinWhite;
        }

        &.standard {
            line-height: 1.4;
            padding: 1rem;
        }

        &.big {
            line-height: 1.4;
            padding: 1rem;
        }

        &.gerunded {
            border-radius: 2rem;
        }
    }
}

.backlink {
    border-radius: 1rem;
    padding-left: 1rem;
    padding-right: 2rem;
    height: 2rem;
    background-color: medinBlue;
    color: medinWhite;
    text-align: left;
    margin-bottom: 1rem;
}

// --------------------------------------
// SHOUTBOX
// -------------------------------------
article.shoutbox {
    color: medinWhite;
    background-color: medinBlue;
    padding: 1rem;
    margin-bottom: 1rem;

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }

    h2, h3 {
        margin-bottom: 1rem;
    }

    a {
        text-decoration: underline;
    }
}

// --------------------------------------
// TEAMMITGLIEDER
// -------------------------------------
.teammitglied {
    a {
        color: medinBlack;

        &:hover {
            text-decoration: underline;
        }
    }

    .weiteres {
        p {
            margin-bottom: 1rem;
        }

        ul {
            list-style: disc;
            margin-left: 1.5rem;
            margin-bottom: 2rem;

            li {
                margin: 0.5rem 0;
            }
        }
    }

    .teambeschreibung {
        margin-bottom: 1rem;

        &.basics {
            margin-bottom: 2rem;
        }
    }

    .basics p {
        font-size: 20px;
        font-size: 1.25rem;
    }

    @media screen and (max-width: 767px) {
        #stage {
            order: 2;
        }

        aside {
            order: 1;
        }
    }

    margin-top: 1rem;

    @media screen and (min-width: 768px) {
        margin-top: 4rem;
    }

    @media screen and (min-width: 1920px) {
        margin-top: 9rem;

        h2 {
            margin-bottom: 4.3rem;
        }

        h3 {
            margin-bottom: 0;
        }

        p, a {
        }

        .teambeschreibung {
            margin-bottom: 2rem;
        }
    }
}

#team {
    h2 {
        margin-bottom: 1rem;
    }
}

// --------------------------------------
// Staggering Animation NAV
// -------------------------------------
#mainHead {
    .ctaCont {
        opacity: 0;
    }

    &.active {
        .ctaCont {
            opacity: 1;
        }
    }
}

// --------------------------------------
// MARGINS
// -------------------------------------
.text h2 {
    margin-bottom: 1.8rem;

    @media screen and (min-width: 768px) {
        margin-bottom: 0.8rem;
    }

    @media screen and (min-width: 1920px) {
        margin-bottom: 1.8rem;
    }
}

p.lead {
    margin-bottom: 2.6rem;

    @media screen and (min-width: 768px) {
        margin-bottom: 1.8rem;
    }
}

// --------------------------------------
// Anim arrow
// -------------------------------------
.spinner {
    position: relative;
}

.anim {
    display: none;
}

.home .fullscreen .anim {
    display: none;
}

.fullscreen {
    @media screen and (min-width: 768px) {
        .anim {
            display: inline;
            width: 5rem;
            opacity: 0;
            position: absolute;
            left: 2rem;
            animation: falling 2s ease 4s 3 forwards;
        }
    }
}

@media screen and (min-width: 1921px) {
    .anim {
        display: inline;
        width: 10rem;
        opacity: 0;
        position: absolute;
        left: 0;
        animation: falling 2s ease 7s 3 forwards;
    }
}

@keyframes falling {
    0% {
        opacity: 0;
        transform: rotate(90deg) translateX(-20vh);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: rotate(90deg) translateX(20vh);
    }
}

// --------------------------------------
// SUCHRESULTATE
// -------------------------------------
.searchresults {
    #mainBody {
        position: relative;
    }
}

#suchresultate {
    li {
        margin-left: 2.5rem;
        margin-bottom: 0.5rem;

        &:first-child {
            margin-top: 1rem;
        }

        a {
            color: medinBlack;
            font-size: 1.5rem;

            // hyphens: auto;
            // word-break: break-all;
            &::before {
                content: '→';
                color: medinBlack;
                position: absolute;
                left: 0rem;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                color: medinBlue;

                &::before {
                    left: 0.75rem;
                    color: medinBlue;
                }
            }
        }
    }
}

// --------------------------------------
// Embeds
// -------------------------------------
.embed {
    margin-bottom: 2rem;

    iframe {
        max-width: 100%;
    }
}

.responsive {
    position: relative;
    padding-bottom: 56.2%;
    height: 0;
    overflow: hidden;
}

.responsive iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
