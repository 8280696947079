@import '../styles/reset.css';
@font-face {
  font-family: 'SuisseIntl';
  src: url("~/fonts/SuisseIntl-Regular-WebXL.eot"); /* IE9 Compat Modes */
  src: url("~/fonts/SuisseIntl-Regular-WebXL.eot?#iefix") format('embedded-opentype'), url("~/fonts/SuisseIntl-Regular-WebXL.woff2") format('woff2'), url("~/fonts/SuisseIntl-Regular-WebXL.woff") format('woff'), url("~/fonts/SuisseIntl-Regular-WebXL.ttf") format('truetype'), url("~/fonts/SuisseIntl-Regular-WebXL.svg#svgFontName") format('svg');
/* Legacy iOS */
}
@import url("//hello.myfonts.net/count/39f557");
@font-face {
  font-family: 'FuturaBT-Medium';
  src: url("~/fonts/webfonts/39F557_0_0.eot");
  src: url("~/fonts/webfonts/39F557_0_0.eot?#iefix") format('embedded-opentype'), url("~/fonts/webfonts/39F557_0_0.woff2") format('woff2'), url("~/fonts/webfonts/39F557_0_0.woff") format('woff'), url("~/fonts/webfonts/39F557_0_0.ttf") format('truetype');
}
body {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  hyphens: auto;
}
h1,
h2,
h4,
h5,
h6,
h3.accordeonTitle {
  text-transform: uppercase;
  font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0;
  line-height: 1.2;
}
h3 {
  margin: 0;
  line-height: 1.2;
}
h1.logo {
  font-size: 55px;
  font-size: 3.43rem;
}
h2,
h4,
h5,
h6,
h3.titel,
h2.teamtitel,
h3.accordeonTitle {
  font-size: 28px;
  font-size: 1.75rem;
}
h3 {
  font-size: 27px;
  font-size: 1.6875rem;
}
h3.untertitel,
.text h4 {
  font-size: 20px;
  font-size: 1.25rem;
}
.text h4,
.shoutbox h3 {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
  margin-bottom: 0.8rem;
  line-height: 1.4;
}
p.lead,
.cta,
.ctaButton,
.shoutbox h3,
h3 {
  font-size: 20px;
  font-size: 1.25rem;
}
p {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.4;
}
@media screen and (min-width: 768px) {
  .text h2 {
    font-size: 40px;
    font-size: 2.5rem;
  }
}
@media screen and (min-width: 900px) {
  h2.maintitle {
    font-size: 44px;
    font-size: 2.75rem;
  }
}
@media screen and (min-width: 1400px) {
  h2.maintitle {
    font-size: 60px;
    font-size: 3.75rem;
  }
}
@media screen and (min-width: 1920px) {
  h1.logo,
  h2,
  .text h2,
  .hero h2,
  h2.teamtitel,
  .accordeon h3 {
    font-size: 44px;
    font-size: 2.75rem;
  }
  h4,
  h5,
  h6 {
    font-size: 30px;
    font-size: 1.875rem;
  }
  p.lead,
  .cta,
  .ctaButton,
  .shoutbox h3,
  h3 {
    font-size: 30px;
    font-size: 1.875rem;
  }
  h3.untertitel,
  .text h4 {
    font-size: 30px;
    font-size: 1.875rem;
  }
  p,
  li {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
header h2 {
  font-size: 28px;
  font-size: 1.75rem;
}
header h2.sublogo {
  font-size: 16px;
  font-size: 1rem;
}
@media screen and (min-width: 1920px) {
  header h2.sublogo {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
header h3 {
  font-size: 16px;
  font-size: 1rem;
  font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
header .langSwitch {
  font-size: 20px;
  font-size: 1.25rem;
}
@media screen and (min-width: 768px) {
  header h2 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  header h3 {
    font-size: 16px;
    font-size: 1rem;
    font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  header .langSwitch {
    font-size: 16px;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1920px) {
  header h2 {
    font-size: 22px;
    font-size: 1.375rem;
  }
  header h3 {
    font-size: 18px;
    font-size: 1.125rem;
    font-family: 'FuturaBT-Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  header .langSwitch {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
.hero h2 {
  font-size: 22px;
  font-size: 1.375rem;
}
@media screen and (min-width: 768px) {
  .hero h2 {
    font-size: 40px;
    font-size: 2.5rem;
  }
  .hero .lead {
    top: 0;
  }
  .hero .lead h2.bildLegende {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
@media screen and (min-width: 1920px) {
  .hero h2 {
    font-size: 80px;
    font-size: 5rem;
  }
  .hero .ctas h2 {
    font-size: 28px;
    font-size: 1.75rem;
  }
}
.blocklink h4,
h4.untertitel {
  font-size: 16px;
  font-size: 1rem;
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-transform: none;
}
@media screen and (min-width: 768px) {
  .blocklink h4,
  h4.untertitel {
    font-size: 22px;
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 1920px) {
  .blocklink h4,
  h4.untertitel {
    font-size: 20px;
    font-size: 1.25rem;
  }
}
.cta h2 {
  font-size: 27px;
  font-size: 1.6875rem;
}
@media screen and (min-width: 1920px) {
  .cta h2 {
    font-size: 27px;
    font-size: 1.6875rem;
  }
}
footer p {
  font-size: 13px;
  font-size: 0.81rem;
}
@media screen and (min-width: 1920px) {
  footer p {
    font-size: 18px;
    font-size: 1.125rem;
  }
}
input,
textarea {
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
body {
  opacity: 1;
}
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
main {
  flex: 1;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  main {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 1920px) {
  main {
    margin-top: 5rem;
  }
}
#mainBody {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  margin-top: 3rem;
}
@media screen and (min-width: 768px) {
  #mainBody {
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem;
  }
  #mainBody .maintitle {
    grid-row: 1;
    grid-column: 1/2;
    margin-top: -0.35rem;
  }
  #mainBody .hero {
    grid-column: 2/3;
    grid-row: 2;
  }
  #mainBody #stage {
    grid-row: 2;
    margin-top: -0.3rem;
  }
  #mainBody.teammitglied #stage {
    grid-row: 1;
  }
  #mainBody .boxes {
    grid-column: 1/3;
  }
  #mainBody aside {
    grid-column: 2/3;
    grid-row: 1;
  }
  #mainBody.nohero {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1920px) {
  #mainBody.nohero {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 768px) and (min-width: 1400px) {
  #mainBody .maintitle {
    margin-top: -0.5rem;
  }
}
.kontakt #mainBody #stage {
  grid-row: 1;
}
.kurztext a {
  text-decoration: underline;
  color: #000;
}
.kurztext a:hover {
  color: #000;
}
figure {
  margin-bottom: 2rem;
}
.landingPage ul.switcheridooo {
  margin-left: 1rem;
  margin-top: 2rem;
  position: relative;
  list-style: none;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  height: 40vh;
  justify-content: center;
}
.landingPage ul.switcheridooo li {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 2rem;
}
.landingPage ul.switcheridooo li::before {
  content: '→';
  color: #0a41c8;
  position: absolute;
  left: 0;
  transition: left 0.3s ease-in-out;
}
.landingPage ul.switcheridooo li:hover {
  color: #0a41c8;
}
.landingPage ul.switcheridooo li::before {
  left: 0.25rem;
}
.landingPage ul.switcheridooo li a {
  text-decoration: none;
  color: #0a41c8;
}
.landingPage .switcheridooo {
  opacity: 0;
}
.landingPage main {
  margin-top: 0;
}
.landingPage .videoDone {
  overflow: hidden;
  position: relative;
}
.landingPage .videoDone .outer {
  position: absolute;
  top: 0;
  width: 100%;
}
.landingPage .videoDone #myvid {
  transition: transform 0.3s ease;
  transform: translateY(-100%);
}
.landingPage .videoDone .switcheridooo {
  transition: opacity 0.3s ease;
  transition-delay: 0.3s;
  opacity: 1;
}
nav ul.children li.active a {
  text-decoration: underline;
}
.hero {
  position: relative;
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .hero {
    margin-bottom: 2rem;
  }
}
@media screen and (min-width: 768px) {
  .hero {
    margin-bottom: 4rem;
  }
}
.hero .bild {
  width: 100%;
}
@media screen and (min-width: 786px) and (max-width: 1920px) {
  .hero.heroleft img {
    height: 70vh;
    width: auto;
  }
}
.hero .lead {
  position: absolute;
  left: 0;
  top: 1rem;
  width: 100%;
}
.hero .lead h2 {
  color: #fff;
}
.hero .ctas {
  display: none;
}
@media screen and (min-width: 1050px) {
  .hero .ctas {
    display: block;
    position: absolute;
    top: 30%;
    width: 100%;
    text-align: right;
  }
  .hero .ctas.nohero {
    display: none;
  }
  .hero .ctas li {
    position: absolute;
    right: 0;
  }
  .hero .ctas li.bigredbutton {
    right: 6.5rem;
  }
  .hero .ctas li.blocklink {
    top: 17rem;
  }
}
@media screen and (min-width: 1400px) {
  .hero .ctas {
    top: 40%;
  }
}
.maincontent {
  width: 100%;
}
.text {
  padding-left: 0;
  margin-bottom: 2rem;
}
@media screen and (min-width: 768px) {
  .text {
    margin-bottom: 75px;
    margin-bottom: 1.8rem;
  }
}
.text a {
  color: #000;
  text-decoration: underline;
}
.text p {
  margin-bottom: 2rem;
}
.text h3 {
  margin-bottom: 0.8rem;
}
.text ul {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 1.1rem;
  margin-bottom: 2.6rem;
}
@media screen and (min-width: 768px) {
  .text ul {
    width: 75%;
  }
}
@media screen and (min-width: 1920px) {
  .text ul {
    margin-bottom: 1.6rem;
    width: 50%;
  }
}
.text ul li {
  line-height: 1.4;
}
@media screen and (min-width: 1080px) {
  .text {
    width: 85%;
  }
}
.inner {
  max-width: 1640px;
  margin: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
.hyphens {
  hyphens: auto;
}
.bigredbutton {
  background-color: #f00;
  width: 13rem;
  height: 13rem;
  border-radius: 6.5rem;
  text-align: center;
}
.bigredbutton a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bigredbutton h2 {
  margin-bottom: 0;
  padding-top: 0.4rem;
}
.blocklink {
  width: 245px;
  background-color: #0a41c8;
  color: #fff;
  text-align: right;
  height: 4rem;
  width: 13rem;
}
.blocklink a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.blocklink h2 {
  margin-bottom: 0;
  padding-left: 1rem;
  padding-top: 0.4rem;
}
video.selfhostedVideo {
  width: 100% !important;
  height: auto !important;
}
.VueCarousel .VueCarousel-pagination {
  text-align: left;
  margin: 0 0 1rem 1rem;
}
.VueCarousel button.VueCarousel-dot {
  margin: 0 0.5rem 0 0;
  background: #fff;
  border: #fff solid 2px;
  opacity: 1;
}
.VueCarousel button.VueCarousel-dot.VueCarousel-dot--active {
  background: transparent;
}
.VueCarousel .VueCarousel-slide-adjustableHeight {
  display: block !important;
}
.VueCarousel .VueCarousel-inner {
  align-items: flex-start !important;
}
footer {
  background-color: #0a41c8;
  color: #fff;
  padding-top: 1rem;
}
footer .footerGrid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  margin-top: 2rem;
  margin-bottom: 3rem;
}
footer .footerGrid .footerlogo {
  width: 80%;
}
footer .footerGrid li {
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  footer .footerGrid {
    grid-template-columns: 4fr 4fr 2fr 1fr 1fr;
    grid-template-rows: auto;
  }
  footer .footerGrid li {
    align-self: end;
  }
  footer .footerGrid .oeffnungszeiten,
  footer .footerGrid .links {
    padding-left: 1rem;
  }
}
footer .socialIcons li {
  display: inline-block;
  margin-left: 0;
  width: 50px;
  width: 3rem;
}
footer .socialIcons li:not(:last-child) {
  margin-right: 1rem;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  footer .socialIcons li {
    width: 30px;
    width: 2rem;
  }
}
@media screen and (min-width: 768px) {
  footer .socialIcons {
    text-align: right;
    margin-left: 1rem;
    margin-right: 0;
  }
}
.red header#mainHead {
  background-color: #f00;
  transition: background-color 0.3s ease-in-out;
}
.red header#mainHead.active,
.red header#mainHead.is-open {
  background-color: #db3021;
}
.red .drawer .red {
  display: none;
}
.red .drawer a.cta.white {
  background-color: #fff;
  color: #f00;
}
.red .hero {
  display: none;
}
.red .maincontent,
.red #app,
.red footer {
  background-color: #f00;
  color: #fff;
}
@media screen and (min-width: 768px) {
  .red .maincontent {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1920px) {
  .red .maincontent {
    margin-top: 8.43rem;
  }
}
.blue .hero {
  display: none;
}
.blue .maincontent,
.blue #app,
.blue footer {
  background-color: #0a41c8;
  color: #fff;
}
.blue header#mainHead {
  transition: background-color 0.3s ease-in-out;
}
.blue header#mainHead.active,
.blue header#mainHead.is-open {
  background-color: #183cb2;
}
.blue a {
  color: #fff;
}
#contactform input,
#contactform textarea {
  background-color: #efefef;
  border: none;
  border-radius: 0;
  margin-top: 0.1rem;
  margin-bottom: 1rem;
  padding-left: 0.5rem;
  color: #000;
  opacity: 1;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: calc(100% - 0.5rem);
  font-size: 18px;
  font-size: 1rem;
}
@media screen and (min-width: 1920px) {
  #contactform input,
  #contactform textarea {
    height: 40px;
    height: 2.5rem;
    margin-top: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
}
#contactform input::placeholder,
#contactform textarea::placeholder {
  color: #000;
  font-size: 18px;
  font-size: 1rem;
}
#contactform input[type='submit'],
#contactform textarea[type='submit'] {
  width: 50%;
  background-color: #0a41c8;
  color: #fff;
  float: right;
}
@media screen and (min-width: 1920px) {
  #contactform input[type='submit'],
  #contactform textarea[type='submit'] {
    max-width: 121px;
    max-width: 7.56rem;
  }
}
#contactform textarea {
  border: none;
}
@media screen and (min-width: 1920px) {
  #contactform textarea {
    height: 4.8125rem;
    padding-top: 0.75rem;
  }
}
#kontaktinfos {
  margin-bottom: 3rem;
}
@media screen and (min-width: 1920px) {
  #kontaktinfos {
    margin-bottom: 8.125rem;
  }
}
#kontaktinfos a {
  color: #000;
  text-decoration: underline;
}
#kontaktinfos a:hover {
  color: #000;
}
.Gmap {
  width: 100%;
  position: relative;
}
.Gmap a {
  display: block;
}
.Gmap .btt {
  position: absolute;
  width: 100%;
  height: 2rem;
  bottom: 2rem;
}
.Gmap .btt .inner,
.Gmap .btt a,
.Gmap .btt .longarrow {
  height: 100%;
}
.Gmap .btt a {
  text-align: right;
}
.kontakt main {
  margin-bottom: 0;
}
a.ctaButton,
button.ctaButton {
  color: #fff;
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline-block;
  width: fit-content;
  margin-bottom: 2rem;
}
a.ctaButton.blau,
button.ctaButton.blau {
  background-color: #0a41c8;
}
a.ctaButton.rot,
button.ctaButton.rot {
  background-color: #f00;
}
a.ctaButton.standard,
button.ctaButton.standard {
  line-height: 1.4;
  padding: 1rem;
}
a.ctaButton.big,
button.ctaButton.big {
  line-height: 1.4;
  padding: 1rem 2rem;
}
a.ctaButton.gerunded,
button.ctaButton.gerunded {
  border-radius: 3rem;
}
.red a.ctaButton {
  color: #f00;
  font-family: 'SuisseIntl', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  display: inline-block;
  margin-bottom: 4rem;
}
.red a.ctaButton.blau {
  background-color: #fff;
}
.red a.ctaButton.rot {
  background-color: #fff;
}
.red a.ctaButton.standard {
  line-height: 1.4;
  padding: 1rem;
}
.red a.ctaButton.big {
  line-height: 1.4;
  padding: 1rem;
}
.red a.ctaButton.gerunded {
  border-radius: 2rem;
}
.backlink {
  border-radius: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  height: 2rem;
  background-color: #0a41c8;
  color: #fff;
  text-align: left;
  margin-bottom: 1rem;
}
article.shoutbox {
  color: #fff;
  background-color: #0a41c8;
  padding: 1rem;
  margin-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  article.shoutbox {
    margin-bottom: 0;
  }
}
article.shoutbox h2,
article.shoutbox h3 {
  margin-bottom: 1rem;
}
article.shoutbox a {
  text-decoration: underline;
}
.teammitglied {
  margin-top: 1rem;
}
.teammitglied a {
  color: #000;
}
.teammitglied a:hover {
  text-decoration: underline;
}
.teammitglied .weiteres p {
  margin-bottom: 1rem;
}
.teammitglied .weiteres ul {
  list-style: disc;
  margin-left: 1.5rem;
  margin-bottom: 2rem;
}
.teammitglied .weiteres ul li {
  margin: 0.5rem 0;
}
.teammitglied .teambeschreibung {
  margin-bottom: 1rem;
}
.teammitglied .teambeschreibung.basics {
  margin-bottom: 2rem;
}
.teammitglied .basics p {
  font-size: 20px;
  font-size: 1.25rem;
}
@media screen and (max-width: 767px) {
  .teammitglied #stage {
    order: 2;
  }
  .teammitglied aside {
    order: 1;
  }
}
@media screen and (min-width: 768px) {
  .teammitglied {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 1920px) {
  .teammitglied {
    margin-top: 9rem;
  }
  .teammitglied h2 {
    margin-bottom: 4.3rem;
  }
  .teammitglied h3 {
    margin-bottom: 0;
  }
  .teammitglied .teambeschreibung {
    margin-bottom: 2rem;
  }
}
#team h2 {
  margin-bottom: 1rem;
}
#mainHead .ctaCont {
  opacity: 0;
}
#mainHead.active .ctaCont {
  opacity: 1;
}
.text h2 {
  margin-bottom: 1.8rem;
}
@media screen and (min-width: 768px) {
  .text h2 {
    margin-bottom: 0.8rem;
  }
}
@media screen and (min-width: 1920px) {
  .text h2 {
    margin-bottom: 1.8rem;
  }
}
p.lead {
  margin-bottom: 2.6rem;
}
@media screen and (min-width: 768px) {
  p.lead {
    margin-bottom: 1.8rem;
  }
}
.spinner {
  position: relative;
}
.anim {
  display: none;
}
.home .fullscreen .anim {
  display: none;
}
@media screen and (min-width: 768px) {
  .fullscreen .anim {
    display: inline;
    width: 5rem;
    opacity: 0;
    position: absolute;
    left: 2rem;
    animation: falling 2s ease 4s 3 forwards;
  }
}
@media screen and (min-width: 1921px) {
  .anim {
    display: inline;
    width: 10rem;
    opacity: 0;
    position: absolute;
    left: 0;
    animation: falling 2s ease 7s 3 forwards;
  }
}
.searchresults #mainBody {
  position: relative;
}
#suchresultate li {
  margin-left: 2.5rem;
  margin-bottom: 0.5rem;
}
#suchresultate li:first-child {
  margin-top: 1rem;
}
#suchresultate li a {
  color: #000;
  font-size: 1.5rem;
}
#suchresultate li a::before {
  content: '→';
  color: #000;
  position: absolute;
  left: 0rem;
  transition: all 0.3s ease-in-out;
}
#suchresultate li a:hover {
  color: #0a41c8;
}
#suchresultate li a:hover::before {
  left: 0.75rem;
  color: #0a41c8;
}
.embed {
  margin-bottom: 2rem;
}
.embed iframe {
  max-width: 100%;
}
.responsive {
  position: relative;
  padding-bottom: 56.2%;
  height: 0;
  overflow: hidden;
}
.responsive iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@-moz-keyframes falling {
  0% {
    opacity: 0;
    transform: rotate(90deg) translateX(-20vh);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(90deg) translateX(20vh);
  }
}
@-webkit-keyframes falling {
  0% {
    opacity: 0;
    transform: rotate(90deg) translateX(-20vh);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(90deg) translateX(20vh);
  }
}
@-o-keyframes falling {
  0% {
    opacity: 0;
    transform: rotate(90deg) translateX(-20vh);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(90deg) translateX(20vh);
  }
}
@keyframes falling {
  0% {
    opacity: 0;
    transform: rotate(90deg) translateX(-20vh);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(90deg) translateX(20vh);
  }
}
@import '../styles/vue-tasty-burgers.css';
@media all and (-ms-high-contrast: none) {
  header .langSwitcherer {
    width: 100%;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
  html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  #mainHead .innerHeader {
    display: flex;
    justify-content: space-between;
  }
  #mainHead nav {
    width: 100%;
  }
  #mainHead .logos {
    width: 20%;
  }
  #mainHead .parents {
    display: flex;
    justify-content: space-between;
  }
  #mainHead .subnav div {
    display: block;
  }
}
@media all and (-ms-high-contrast: none) {
  #app {
    display: block;
  }
  #app .hero img {
    width: 100%;
  }
  #app main {
    display: block;
    margin: 2rem;
    padding-top: 2rem;
    min-height: calc(100vh - 200px);
  }
  #app #mainBody {
    display: block;
  }
  #app #mainBody .maintitle {
    padding-top: 1rem;
  }
  #app #mainBody.teammitglied {
    display: flex;
  }
  #app #mainBody.teammitglied #stage {
    width: calc(100% / 3 * 2);
  }
  #app #mainBody.teammitglied aside {
    width: calc((100% / 3));
  }
  #app .contentboxes,
  #app .teamboxes {
    overflow: hidden;
    margin-bottom: 2rem;
  }
  #app .contentboxes ul,
  #app .teamboxes ul {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 0 20px;
    margin: 0 -20px -20px 0;
  }
  #app .contentboxes ul li.contentbox,
  #app .teamboxes ul li.contentbox {
    width: calc((100% / 2));
    height: 400px;
    border: 0 solid transparent;
    border-width: 0 20px 20px 0;
    background-clip: padding-box;
  }
  #app .contentboxes ul li.contentbox h3.titel,
  #app .teamboxes ul li.contentbox h3.titel {
    font-size: 1rem;
  }
  #app .contentboxes ul li.contentbox .blocklink h4,
  #app .teamboxes ul li.contentbox .blocklink h4 {
    font-size: 0.8rem;
  }
  #app .contentboxes ul li.mitglied,
  #app .teamboxes ul li.mitglied {
    width: calc((100% / 2));
    height: 200px;
    border: 0 solid transparent;
    border-width: 0 20px 20px 0;
    background-clip: padding-box;
  }
  #app .contentboxes ul li.mitglied::before,
  #app .teamboxes ul li.mitglied::before {
    display: none;
  }
  #app .contentboxes ul li.mitglied:hover a,
  #app .teamboxes ul li.mitglied:hover a {
    color: #000;
  }
  #app .contentboxes ul li.mitglied h3.titel,
  #app .teamboxes ul li.mitglied h3.titel {
    font-size: 1.75rem;
  }
  #app .contentboxes ul li.mitglied .blocklink h4,
  #app .teamboxes ul li.mitglied .blocklink h4 {
    font-size: 1.25rem;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 1100px) {
  #app .contentboxes ul li.contentbox {
    width: calc((100% / 3));
  }
  #app .teamboxes ul li.mitglied {
    width: calc((100% / 3));
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
  #app #mainBody {
    display: grid;
    height: 100%;
    grid-template: 'a b' 'c d' 'e';
  }
  #app .hero {
    grid-area: d;
  }
  #app .maintitle {
    grid-area: a;
  }
  #app aside {
    grid-area: b;
  }
  #app #stage {
    grid-area: c;
  }
  #app .boxes {
    grid-area: e;
    -ms-grid-column-span: 3;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
  .accordeon .arrowContainer {
    display: none;
  }
}
@media all and (-ms-high-contrast: none) and (min-width: 768px) {
  .ueberUns .contentbox picture {
    display: none;
  }
}
/*# sourceMappingURL=src/components/Styles.css.map */